import React from "react"
import * as style from "./learn_more.module.css"
import Button from "../buttons/overview_button"
import blackboard from "../../images/blackboard.png"
import atom from "../../images/atom.png"
// import { Link } from "gatsby"

export const LearnMoreComponent = ({ text, link = "/" }) => {
  const steam_url = "https://steam.angazaelimu.com"
  const learn_url = "https://elearning.angazaelimu.com/login"

  return (
    <div className={style.learn_more}>
      <div className={style.learn_more_wrapper}>
        <div className={style.learn_more__item}>
          <img src={blackboard} alt="blackboard" />
          <h2>Interactive E-Learning</h2>
          <p>
            Get access to quality Lesson Notes, KCSE & KCPE past papers, quizes
            and assignments, perfomance progress review, and discussion forums.
          </p>
          <a href={`${learn_url}${link}`}>
            <Button text={text} />
          </a>
        </div>

        <div className={style.learn_more__item}>
          <img src={atom} alt="atom" />
          <h2>Innovators STEAME</h2>
          <p>
            Personalised STEAME (Science, Technology, Engineering, Art, Math &
            Entrepreneurship) training to help you launch your career and
            business.
          </p>
          <a href={`${steam_url}${link}`}>
            <Button text={text} />
          </a>
        </div>
      </div>
    </div>
  )
}
