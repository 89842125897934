import React from 'react'
import Layout from "../components/layout/index"
import Seo from "../components/seo"
import { LearnMoreComponent } from '../components/learn_more/index'

const LearnMore = () => {
  return (
    <Layout>
      <Seo title="Learn More" />
      <LearnMoreComponent link='/' text='Login'/>
  </Layout>
  )
}

export default LearnMore